import { MenuItem } from 'primeng/api';
import { FilterOptionValue } from '../enums/search.enums';

export interface FilterOption<T> {
  label: string;
  value: T;
}

export interface SearchResult {
  id: number | string;
  title: string;
  description: string;
  url: string;
  contentCategory?: ContentCategory;
  breadcrumb?: MenuItem;
}

export interface SearchResponse {
  id: number | string;
  title: string;
  description: string;
  externalLink?: string;
  contentCategory?: ContentCategory;
}

export interface SearchQueryOption {
  keyword: string;
  filter: FilterOptionValue;
}

export type SearchCounts = {
  [key in FilterOptionValue]: number;
};

interface ContentCategory {
  id: number;
  name?: string;
}
