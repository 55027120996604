import { Status } from '@avenir-client-web/enums';
import { FileItem } from '@avenir-client-web/file';
import { DashboardBaseContentItem } from '@core/models/dashboard-base-content-item.model';
import { MetadataItem } from '@core/models/metadata-item.model';
import { User } from '@core/models/profile.model';
import { LandingPageSlot } from '@shared/enums/landing-page-slot.enum';

export interface ContentCategory {
  id: number;
  title?: string;
  description?: string;
  responsiblePersons?: User[];
  languages: string[];
  status: Status;
  landingPageSlot: LandingPageSlot;
  groupImageId: string;
  previewImageId: string;
  hasRelatedContent: boolean;
}

export interface ContentCategoryOverview extends DashboardBaseContentItem {
  relatedProcesses: DashboardBaseContentItem[];
}

export interface DetailContentCategory {
  id: number;
  status: Status;
  responsiblePersons: User[];
  emailTemplateId: number;
  infos: ContentCategoryInfo[];
  groupImage: FileItem;
  previewImage: FileItem;
}

export interface ContentCategoryInfo {
  id: number;
  title: string;
  description: string;
  language: MetadataItem;
}

export interface EmailTemplate {
  id: number;
  name: string;
}

export interface ContentCategoryMetadata {
  emailTemplates: EmailTemplate[];
  languages: MetadataItem[];
}

export interface RelatedContent {
  id: number;
  contentCategory?: ContentCategory;
  title: string;
  type: RelatedContentType;
}

export enum RelatedContentType {
  NONE,
  NEWS,
  PROCESS,
  WIKI_INDEX,
  WIKI_DETAIL,
}

export const RelatedContentLabel = {
  [RelatedContentType.NEWS]: $localize`contentCategories.relatedContents.news`,
  [RelatedContentType.PROCESS]: $localize`contentCategories.relatedContents.process`,
  [RelatedContentType.WIKI_INDEX]: $localize`contentCategories.relatedContents.wikiIndex`,
  [RelatedContentType.WIKI_DETAIL]: $localize`contentCategories.relatedContents.wikiDetail`,
};

export interface FilterOption {
  label: string;
  value: number | string;
}
