import { FileExtension } from '../enums/file-extension.enum';

const documentRoute = 'document';

const previewUrlRegexString = `^${location.origin}/${documentRoute}/(.+)/(.+)\\.(.+)`;

const previewUrlRegex = new RegExp(previewUrlRegexString);

const internalUrlRegexString = `^${location.origin}`;

const internalUrlRegex = new RegExp(internalUrlRegexString);

export function shouldPreview(extension: FileExtension): boolean {
  return [FileExtension.DOCX, FileExtension.DOC].every(i => i !== extension);
}

export function generatePreviewUrl(
  id: string | number,
  name: string,
  extension: FileExtension
): string {
  return `${location.origin}/${documentRoute}/${id}/${encodeURIComponent(name)
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')}.${extension}`;
}

export function getFileExtension(fileName: string): FileExtension {
  return fileName.split('.')?.pop() as FileExtension;
}

export function isInternalPreviewUrl(url: string): boolean {
  return previewUrlRegex.test(url);
}

export function extractFileInfoFromUrl(url: string): RegExpExecArray {
  return previewUrlRegex.exec(url);
}

export function isInternalUrl(url: string): boolean {
  return internalUrlRegex.test(url);
}
