import { RelatedContentType } from '@app/content-category/models/content-category.models';

interface CurrentContent {
  contentType?: RelatedContentType;
  relatedContentId?: number;
  categoryId?: number;
}

export class AppContentService {
  private static content: CurrentContent = {};

  static setType(contentType: RelatedContentType): void {
    this.resetContent();
    this.content.contentType = contentType;
  }

  static setContent(relatedContentId: number, categoryId: number): void {
    this.content.categoryId = categoryId;
    this.content.relatedContentId = relatedContentId;
  }

  static getCurrentContent(): CurrentContent {
    return this.content;
  }

  static resetContent(): void {
    this.content = {};
  }
}
