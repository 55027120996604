import { Component, ViewEncapsulation } from '@angular/core';
import { ProgressSpinnerService } from '@avenir-client-web/progress-spinner';
import { combineLatest, map, Observable } from 'rxjs';
import { ProgressBarService } from '../services/progress-bar.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressBarComponent {
  isShowProgressBar$: Observable<boolean>;

  constructor(
    private readonly progressBarService: ProgressBarService,
    private readonly progressSpinnerService: ProgressSpinnerService
  ) {
    this.isShowProgressBar$ = combineLatest(
      this.progressBarService.isShowProgressBar$,
      this.progressSpinnerService.isProgressSpinnerShown$
    ).pipe(map(([bar, spinner]) => bar && !spinner));
  }
}
