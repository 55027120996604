import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { InputText } from 'primeng/inputtext';
import { FilterOptionValue } from '../enums/search.enums';
import { SearchQueryOption } from '../interfaces/search.interfaces';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchBoxComponent<T = unknown> {
  @Input() placeholder: string;

  @Input() keyword = '';

  @Input() filter: T;

  @Output() loadEvent = new EventEmitter<SearchQueryOption>();

  @ViewChild(InputText, {
    static: true,
  })
  input: InputText;

  inputMaxLength = 100;

  emitLoadEvent(): void {
    this.keyword = this.keyword.trim();

    if (!this.keyword.length) return;

    const { keyword } = this;

    this.loadEvent.emit({
      keyword,
      filter: FilterOptionValue.PROCESS,
    });

    this.input.el.nativeElement.blur();
  }
}
