import { AppRootRoute } from '@core/enums/app-route.enum';
import { SidebarItemKey } from '@core/enums/sidebar-item.enum';
import { Permission } from '@core/services/permission.model';
import { MenuItem } from 'primeng/api';

export type Menu = {
  [key in SidebarItemKey]: MenuItem;
};

export const menu: Menu = {
  [SidebarItemKey.DASHBOARD]: {
    label: $localize`menuItems.home`,
    url: `/${AppRootRoute.HOME}`,
    state: {
      permission: Permission.READ,
    },
  },
  [SidebarItemKey.NEWS]: {
    label: $localize`menuItems.news`,
    url: `/${AppRootRoute.NEWS}`,
    state: {
      permission: Permission.READ,
    },
  },
  [SidebarItemKey.WIKIS]: {
    label: $localize`menuItems.wiki`,
    url: `/${AppRootRoute.WIKIS}`,
    state: {
      permission: Permission.READ,
    },
  },
  [SidebarItemKey.PROCESSES]: {
    label: $localize`menuItems.processes`,
    url: `/${AppRootRoute.ADMIN_PROCESSES}`,
    state: {
      permission: Permission.WRITE,
    },
  },
  [SidebarItemKey.FILES]: {
    label: $localize`menuItems.files`,
    url: `/${AppRootRoute.ADMIN_FILES}`,
    state: {
      permission: Permission.WRITE,
    },
  },
  [SidebarItemKey.CONTENT_CATEGORIES]: {
    label: $localize`menuItems.content-categories`,
    url: `/${AppRootRoute.ADMIN_CONTENT_CATEGORIES}`,
    state: {
      permission: Permission.WRITE,
      exact: true,
    },
  },
  [SidebarItemKey.QUICKLINKS]: {
    label: $localize`menuItems.quicklinks`,
    url: `/${AppRootRoute.ADMIN_QUICKLINKS}`,
    state: {
      permission: Permission.WRITE,
    },
  },
  [SidebarItemKey.LANGUAGES]: {
    label: $localize`menuItems.language`,
    url: `/${AppRootRoute.ADMIN_LANGUAGES}`,
    state: {
      permission: Permission.ADMIN,
    },
  },
  [SidebarItemKey.TICKET_OVERVIEW]: {
    label: $localize`menuItems.ticketOverview`,
    url: `/${AppRootRoute.TICKETS}`,
    state: {
      permission: Permission.TICKET,
    },
  },
  [SidebarItemKey.TICKET_MANAGEMENT]: {
    label: $localize`menuItems.ticketManagement`,
    url: `/${AppRootRoute.ADMIN_TICKETS}`,
    state: {
      permission: Permission.TICKET_MANAGEMENT,
    },
  },
  [SidebarItemKey.SEARCH_KEYWORDS]: {
    label: $localize`menuItems.searchKeyword`,
    url: `/${AppRootRoute.ADMIN_SEARCH_KEYWORDS}`,
    state: {
      permission: Permission.ADMIN,
    },
  },
};
