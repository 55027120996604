import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_CONFIG, LogLevel } from '@avenir-client-web/models';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const NOT_FOUND_HTML_PAGE = 'assets/config/not-found.html';

fetch(`/${environment.configUrl}`)
  .then(res => res.json())
  .then(config => {
    if (environment.production) {
      enableProdMode();
    }

    const logLevel = LogLevel[config.logLevel];

    const configToUse = {
      ...config,
      logLevel,
    };

    if (!environment.production) {
      console.log('Running with config', configToUse);
    }

    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: configToUse }])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  })
  .catch(() => {
    window.location.assign(NOT_FOUND_HTML_PAGE);
  });
