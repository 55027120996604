import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  extractFileInfoFromUrl,
  FileExtension,
  FileResponseType,
  FileService,
  isInternalPreviewUrl,
  isInternalUrl,
  shouldPreview,
} from '@avenir-client-web/file';
import { Subject, takeUntil } from 'rxjs';

enum LinkTargetType {
  BLANK = '_blank',
  SELF = '_self',
}

@Directive({
  selector: '[appLinkActionHandler]',
})
export class LinkActionHandlerDirective implements OnDestroy {
  @Input() appLinkActionHandler: string;

  private readonly compUnsubscribe$ = new Subject<void>();

  @HostListener('click', ['$event'])
  onClick(): void {
    this.linkActionHandler();
  }

  constructor(
    private readonly fileService: FileService,
    private readonly router: Router
  ) {}

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }

  private linkActionHandler(): void {
    if (!this.appLinkActionHandler) {
      return;
    }

    if (isInternalUrl(this.appLinkActionHandler)) {
      if (!isInternalPreviewUrl(this.appLinkActionHandler)) {
        return this.openLink(LinkTargetType.SELF);
      }

      const [, fileId, fileName, extension] = extractFileInfoFromUrl(
        this.appLinkActionHandler
      );

      if (shouldPreview(extension as FileExtension)) {
        return this.openLink(LinkTargetType.BLANK);
      }

      return this.downloadFile(fileId, fileName, extension as FileExtension);
    } else {
      return this.openLink(LinkTargetType.BLANK);
    }
  }

  private downloadFile(
    fileId: string,
    fileName: string,
    extension: FileExtension
  ): void {
    this.fileService
      .getFile(fileId, '*', FileResponseType.BLOB)
      .pipe(takeUntil(this.compUnsubscribe$))
      .subscribe(data =>
        this.fileService.downloadFileByBlob(
          data as Blob,
          `${fileName}.${extension}`
        )
      );
  }

  private openLink(target: LinkTargetType): void {
    if (target === LinkTargetType.SELF) {
      this.router.navigateByUrl(
        this.appLinkActionHandler.replace(window.origin, '')
      );
    } else {
      window.open(this.appLinkActionHandler, target);
    }
  }
}
