<div class="search-result">
  <div class="breadcrumb">
    <app-search-breadcrumb [filter]="filter" [searchResult]="searchResult">
    </app-search-breadcrumb>
  </div>
  <div
    class="search-result-detail cursor-pointer"
    [appLinkActionHandler]="searchResult?.url"
  >
    <a class="search-result-detail__link">
      <span class="search-result-detail__title my-3">{{
        searchResult?.title
      }}</span>
    </a>

    <p class="search-result-detail__description">
      {{ searchResult?.description }}
    </p>
  </div>
</div>
