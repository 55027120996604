<div
  class="search-results"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollContainer]="'.main-content-wrapper .scrollable-wrapper'"
  [fromRoot]="true"
  (scrolled)="scrollDown.emit($event)"
>
  <ng-container *ngIf="data?.length; else noResultsFound">
    <app-search-result
      *ngFor="let item of data"
      [filter]="filter"
      [searchResult]="item"
    ></app-search-result>
  </ng-container>

  <ng-template #noResultsFound>
    <span i18n>default.noResultsFound</span>
  </ng-template>
</div>
