import { FilterOption, FilterOptionValue } from '@avenir-client-web/search';

export const filterOptions: FilterOption<FilterOptionValue>[] = [
  {
    label: $localize`search.filter.process`,
    value: FilterOptionValue.PROCESS,
  },
  {
    label: $localize`search.filter.news`,
    value: FilterOptionValue.NEWS,
  },
  {
    label: $localize`search.filter.wiki`,
    value: FilterOptionValue.WIKI,
  },
  {
    label: $localize`search.filter.people`,
    value: FilterOptionValue.PEOPLE,
  },
  {
    label: $localize`search.filter.elearning`,
    value: FilterOptionValue.ELEARNING,
  },
];
