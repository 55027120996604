export const notificationType = {
  ticket: {
    action: {
      create: 'createTicket',
      createBySelf: 'createTicketCreator',
      resolve: 'resolveTicket',
      update: 'updateTicket',
      chat: 'chatTicket',
    },
    info: {
      create: 'createInfoTicket',
    },
  },
  external: {
    easylearn: 'notifyEasylearn',
  },
};
