<div class="field">
  <div class="flex align-items-center justify-content-between">
    <label *ngIf="label" class="block" [ngClass]="{ required: hasAsterisk }"
      >{{ label }}
    </label>
  </div>
  <p-editor
    [readonly]="readonly"
    [placeholder]="placeholder ?? ''"
    class="form-control"
    (onTextChange)="onTextChange($event)"
    [(ngModel)]="value"
    [formats]="formats"
  >
    <ng-template *ngIf="headerTemplate" pTemplate="header">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </ng-template>
  </p-editor>
  <ng-content></ng-content>
</div>
