import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FilterOption, SearchCounts } from '../interfaces/search.interfaces';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchFilterComponent<T = unknown> {
  @Input() filterTitle: string;

  @Input() options: FilterOption<T>[];

  @Input() filter: T;

  @Input() searchCounts: SearchCounts;

  @Output() filterChange = new EventEmitter<T>();
}
