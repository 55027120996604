import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { IInfiniteScrollEvent } from 'ngx-infinite-scroll';
import { FilterOptionValue } from '../enums/search.enums';
import { SearchResult } from '../interfaces/search.interfaces';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchResultsComponent {
  @Input() data: SearchResult[];

  @Input() filter: FilterOptionValue;

  @Output() scrollDown = new EventEmitter<IInfiniteScrollEvent>();
}
