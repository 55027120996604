import { InjectionToken } from '@angular/core';
import { LogLevel } from '@avenir-client-web/models';

export const APP_CONFIG = new InjectionToken<BaseAppConfig>('app.config');

export class BaseAppConfig {
  serverUrl: string;

  defaultLanguage: string;

  supportedLanguages: string[];

  logLevel: LogLevel;

  recaptchaSiteKey?: string;

  copyright?: string;
}
