import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-mail-to-button',
  templateUrl: './mail-to-button.component.html',
  styleUrls: ['./mail-to-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MailToButtonComponent {
  @Input() buttonTitle: string;

  @Output() needHelp = new EventEmitter<void>();

  onClickNeedHelp(): void {
    this.needHelp.next();
  }
}
