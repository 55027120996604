import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppBreadcrumbModule } from '@avenir-client-web/breadcrumb';
import { DropdownModule } from '@avenir-client-web/dropdown';
import { LinkActionHandlerModule } from '@avenir-client-web/link-action-handler';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabViewModule } from 'primeng/tabview';
import { SearchBoxComponent } from './search-box/search-box.component';
import { SearchBreadcrumbComponent } from './search-breadcrumb/search-breadcrumb.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { SearchLayoutComponent } from './search-layout/search-layout.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchSectionComponent } from './search-section/search-section.component';

@NgModule({
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    SelectButtonModule,
    DropdownModule,
    InfiniteScrollModule,
    TabViewModule,
    AppBreadcrumbModule,
    LinkActionHandlerModule,
    AngularSvgIconModule,
    ButtonModule,
  ],
  declarations: [
    SearchSectionComponent,
    SearchLayoutComponent,
    SearchFilterComponent,
    SearchResultsComponent,
    SearchResultComponent,
    SearchBoxComponent,
    SearchBreadcrumbComponent,
  ],
  exports: [
    SearchSectionComponent,
    SearchLayoutComponent,
    SearchFilterComponent,
    SearchResultsComponent,
    SearchBoxComponent,
  ],
})
export class SearchModule {}
